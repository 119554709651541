/** Main file */
const mainBody = document.querySelector('body');

// Home animation
const element1 = document.querySelectorAll('.translate-left1');
const element2 = document.querySelectorAll('.translate-left2');
let start;
let elapsed = 0;
let elapsed2 = 100;
let animationConsumed = false;

function step(timestamp) {
  if (start === undefined)
    start = timestamp;
  elapsed = elapsed - 0.05;
  elapsed2 = elapsed2 - 0.05;
  if (elapsed <= -100) elapsed = 100;
  if (elapsed2 <= -100) elapsed2 = 100;

  element1.forEach((element) => {
    if (elapsed > 0) {
      element.style.transitionDuration = '0s'; // Remove duration
    } else {
        element.style.transitionDuration = `2s`; // Add duration
      }
    element.style.transform = 'translateX(' + elapsed + '%)';
  });
  element2.forEach((element) => {
    if (elapsed > 0) {
      element.style.transitionDuration = '0s'; // Remove duration
    } else {
      element.style.transitionDuration = `2s`; // Add duration
    }
    element.style.transform = 'translateX(' + elapsed2 + '%)';
  });


  window.requestAnimationFrame(step);
}

window.requestAnimationFrame(step);



// Change section color
const serviceSection = document.querySelectorAll('.in-view');
const pageBody = document.querySelector('body');
const blogPageView = document.querySelector('.blog');
const categoryPageView = document.querySelector('.archive');

function handler(entries, observer) {
  entries.forEach(entry => {
    const dataTheme = entry.target.dataset.theme;
    //console.log(dataTheme);
    const element = entry.target;
    var visible = entry.isIntersecting;
    if (element.getAttribute("data-text")) var animClass = element.getAttribute("data-text");

    if (visible) {
      element.classList.add('focused');

      if (element.getAttribute("data-theme")) pageBody.setAttribute("data-theme", dataTheme);
      if (element.getAttribute("data-text") && !animationConsumed) {
        animationConsumed = true;
        setTimeout(() => m11Anim('.first-text'), 200);
        setTimeout(() => m11Anim('.second-text'), 2000);
      }
    } else {
      element.classList.remove('focused')
    }
  });
}

let options = {
  threshold: 0.7,
};

if (blogPageView || categoryPageView) {
  options = {
    threshold: 0.3,
  };
} else {
  options = {
    threshold: 0.7,
  };
}

if (!!window.IntersectionObserver) {
  let observer = new IntersectionObserver(handler, options);
  serviceSection.forEach(service => { observer.observe(service) });
}


// Talent Philosophy Section 
// Wrap every letter in a span
function startAnim(selector, lettersSelector, classToAdd, delayStart) {
  var animText = document.querySelectorAll(selector);
  var animIndex = 0;
  animText.forEach(wrapper => {
    wrapper.innerHTML = wrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
  });
  function animPhiloText() {
    anime.timeline({ loop: 0 })
      .add({
        targets: lettersSelector,
        translateX: [40, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1200,
        loop: 0,
        delay: (el, i) => 500 + 30 * i
      }).add({
        targets: lettersSelector,
        translateX: [0, -30],
        opacity: [1, 0],
        easing: "easeInExpo",
        loop: 0,
        duration: 1100,
        delay: (el, i) => 100 + 30 * i,
        complete: (el) => {
          animText[animIndex].classList.remove(classToAdd);
          if (animIndex === animText.length - 1) animIndex = 0;
          else animIndex++;
          animText[animIndex].classList.add(classToAdd);
          animPhiloText();
        }
      })
  }
  setTimeout(() => {
    animPhiloText();
  }, delayStart);
}

const textAnim = document.querySelector('.text-anim');

if (textAnim) {
  startAnim('.text-anim-left', '.ml12-left .letter', 'ml12-left', 0);
  startAnim('.text-anim-right', '.ml12-right .letter', 'ml12-right', 600);
}

// Burger Menu
const burger = document.querySelector('.navbar-toggler');
const menuItems = document.querySelector('.menu-items');
const menuItem = document.querySelectorAll('.menu-item a');
const langSwitcher = document.querySelector('.lang');

burger.addEventListener('click', toggleMenu);
menuItem.forEach(item => {
  item.addEventListener('click', linkToMenu);
  item.addEventListener('mouseenter', enterMenuItem);
  item.addEventListener('mouseleave', leaveMenuItem);
});

function toggleMenu() {
  mainBody.classList.toggle('menu-open');
  langSwitcher.classList.toggle('lang__burger-menu');
}

function linkToMenu(event) {
  event.preventDefault(event);
  mainBody.classList.remove('menu-open');
  var link = this.getAttribute("href");
  if (this.classList.contains('link-to')) {
       const target = this.getAttribute('target');
       if (target === '_blank') {
         window.open(link, '_blank');
       } else {
         window.open(link, '_self');
       }
  }
  else {
    var reference = document.querySelector(link);

    setTimeout(() => {
      reference.scrollIntoView({
        behavior: 'smooth'
      });
    }, 200);
  }
}

function enterMenuItem() {
  menuItems.classList.add('menu-hover');
  this.style.color = "#fff";
}
function leaveMenuItem() {
  menuItems.classList.remove('menu-hover');
  this.removeAttribute("style");
}



// Contact Intro
const contactPage = document.querySelector('.page-template-contact-page');
const contactIntro = document.querySelector('.contact-intro');

if (contactPage) {
  history.scrollRestoration = "manual";

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }
  setTimeout(() => m11Anim('.first-text'), 200);
  setTimeout(() => m11Anim('.second-text', moveContactIntro), 2000);
};

// What else animation
function spanText(elem) {
  var textWrapper = document.querySelector(elem);
  if (textWrapper) {
    var string = textWrapper.innerHTML;
    var spaned = '';
    for (var i = 0; i < string.length; i++) {
      if (string.substring(i, i + 1) === ' ') spaned += string.substring(i, i + 1);
      else spaned += '<span class="letter">' + string.substring(i, i + 1) + '</span>';
    }
    textWrapper.innerHTML = spaned;
  }
}
spanText('.first-text .letters');
spanText('.second-text .letters');
function m11Anim(element, cb = undefined) {
  anime.timeline({ loop: 0 })
    .add({
      targets: element + ' .line',
      scaleY: [0.5, 1],
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 700
    })
    .add({
      targets: element + ' .line',
      translateX: [0, document.querySelector(element + ' .letters').getBoundingClientRect().width + 10],
      easing: "easeOutExpo",
      duration: 700,
      delay: 100
    }).add({
      targets: element + ' .letter',
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 600,
      offset: '-=775',
      delay: (el, i) => 34 * (i + 1)
    }).add({
      targets: element + ' .line',
      opacity: 0,
      duration: 1000,
      easing: "easeOutExpo",
      complete: (el) => {
        if (cb) cb();
      },
      delay: 100
    });
}

function moveContactIntro() {
  mainBody.classList.add('move-up');
}

/** Subscribe newsletter validation */

function emailNotNull() {
  const form = document.querySelector('#mc-embedded-subscribe-form');
  const subscribe_input = document.querySelector('#mce-EMAIL');

  form.addEventListener('submit', (event) => {
    const subscribe_email = document.getElementById("mce-EMAIL").value;
    if (subscribe_email == '') {
      event.preventDefault();
      subscribe_input.classList.add('error');
    } else {
      subscribe_input.classList.remove('error');
    }
  });
}
emailNotNull();

// Swiper for job
const jobsSwiper = document.querySelector('.swiper-jobs-container');
if (jobsSwiper) {
  var mySwiper = new Swiper('.swiper-jobs-container', {
    // Optional parameters
    loop: true,
    slidesPerView: 3,
    spaceBetween: 60,

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 60,
      },
    }
  })
}
// scroll blog section in homepage
AOS.init({
  duration: 1200,
})

// Swiper for Blog articles
const blogSwiper = document.querySelector('.swiper-blog-container');
if (blogSwiper) {
  var articleSwiper = new Swiper('.swiper-blog-container', {
    // Optional parameters
    loop: true,
    slidesPerView: 2.3,
    spaceBetween: 10,
    freeMode: false,
    centeredSlides: true,
    breakpoints: {
      330: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
  })
}

// Swiper for Blog articles - page blog - mobile & tablet
const blogSwiperArticles = document.querySelector('.swiper-blog-articles-container');
//console.log(blogSwiperArticles);
if (blogSwiperArticles) {
  var blogarticleSwiper = new Swiper('.swiper-blog-articles-container', {
    // Optional parameters
    loop: true,
    slidesPerView: 3,
    spaceBetween: 40,
    freeMode: false,
    centeredSlides: true,
  })
}

// Horizontal scroll - Page blog - desktop
const blogPage = document.querySelector('.blog');
const categoryPage = document.querySelector('.archive');
const catFilters = document.querySelector('.category_filters');
const blogWrapper = document.querySelector('.blog-wrapper');
const blogArticles = document.querySelectorAll('.fact');
const nextDiv = document.querySelector('.category-slider-next-div');

window.addEventListener('resize', function () {
  nextDiv.style.height = `0`;
});
if (blogPage || categoryPage) {
  window.onscroll = function () {
    blogWrapper.classList.add('sticky-section');
    const blogContainer = document.querySelector('.factsContainer_sm');
    const firstElment = document.querySelector('.back-btn');
    const articlesCount = blogArticles.length;

    const lastArticle = blogArticles
      .item(articlesCount - 1)
      .getBoundingClientRect();
    const paddingLeft = getComputedStyle(blogContainer)
      .getPropertyValue('padding-left')
      .split('px')[0];

    const totalWidth = lastArticle.width * articlesCount + +paddingLeft;

    const widthHidden = totalWidth - window.screen.width;

    if (window.screen.width > 1400)
      nextDiv.style.height = `${widthHidden / 6}px`;
    else {
      nextDiv.style.height = `${widthHidden / 4.1}px`;
    }
    
    const sliderinView = firstElment?.getBoundingClientRect().y >= 0;

    if (sliderinView) {
      horizontalScroll();
    }

  };
}

function horizontalScroll() {
  blogArticles.forEach((element) => {
    const positionX = window.pageYOffset;

    element.style.transform = 'translate3d(-' + positionX + '%, 0, 0)';
  });
}

//Style active category __Homepage section Blog
const articlesList = document.querySelectorAll('.scroll-container-desktop .article');
const categoriesList = document.querySelectorAll('.category .blog__title');
const articlesListArr = Array.from(articlesList);
let i = 0;

document.addEventListener('scroll', function () {
  articlesListArr.forEach((element) => {

    if (isInViewport(element)) {
      i = articlesListArr.indexOf(element);
      //console.log(element);
      categoriesList[i].classList.add("is_active");
      if (categoriesList[i - 1]) categoriesList[i - 1].classList.remove("is_active");
      if (categoriesList[i + 1]) categoriesList[i + 1].classList.remove("is_active");

    }

  });
});

function isInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)

  );
}

